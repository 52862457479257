import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { StyledOuterWrapper } from "./../styles/global"
import { deviceMax } from "../styles/mediaqueries"
import LogoHorisontalWhite from "./Illustrations/LogoHorisontalWhite"

interface IStyledFooterContentProps {
    containsImage?: any
}

const StyledFooterSections = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
`

const StyledFooterContent = styled.div<IStyledFooterContentProps>`
    display: flex;
    flex-direction: column-reverse;
    height: 190px;
    ${(props) =>
        props.containsImage &&
        `
        width: 180px;
        height: 100px;
        margin-bottom: 2rem;
    `};
`

const StyledFooter = styled.footer`
    padding: 5rem 5.5rem 4rem;
    background-color: ${colors.xDarkBlue};
    @media ${deviceMax.mobileL} {
        padding: 5rem 5rem 4rem;
    }
    @media ${deviceMax.mobileM}, ${deviceMax.mobileS} {
        padding: 5rem 3rem 4rem;
    }
`
const StyledFooterTextArea = styled.div`
    display: flex;
    flex-direction: column;
    & h3,
    p {
        color: white;
        font-size: 16px;
    }
    & strong {
        color: white;
        font-size: 14px;
    }
    & a {
        color: #92b7f3;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
`

const StyledFooterSplitContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <StyledOuterWrapper footer={true}>
                <StyledFooterSections>
                    <StyledFooterContent>
                        <StyledFooterSplitContent>
                            <StyledFooterTextArea>
                                <p>Besøksadresse: Akersgata 35/39, 0158 Oslo</p>
                                <p>Org.nr: 980 557 707</p>
                                <br />
                                <div className="contact">
                                    <p>24 14 55 20</p>
                                    <p>
                                        <a href="mailto:post@bblpivotal.no">
                                            post@bblpivotal.no
                                        </a>{" "}
                                    </p>
                                </div>
                                <br />
                                <a href="/aapenhetsloven">Åpenhetsloven</a>
                                <br />
                                <a href="/personvernerklaering">
                                    Personvernerklaering
                                </a>
                            </StyledFooterTextArea>
                            <a href="/miljofyrtarn">
                                <img
                                    width="100px"
                                    src="assets/miljfyrtarn-ensfarget-hvit.png"
                                    alt="Miljøfyrtårn logo"
                                />
                            </a>
                        </StyledFooterSplitContent>
                    </StyledFooterContent>
                    <StyledFooterContent containsImage>
                        <div className="image-wrapper">
                            <LogoHorisontalWhite width="100%" height="100%" />
                        </div>
                    </StyledFooterContent>
                </StyledFooterSections>
            </StyledOuterWrapper>
        </StyledFooter>
    )
}
